
.Testimonials__MainContainer{

    display:flex;
    flex-direction: column;
    background:#757f9a;
    align-items: center;
}

.Testimonials__header{
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 90px;
    font-weight: 900;
    color:white;
    /* margin-bottom:20px;
    border-bottom:10px solid rgb(174, 173, 173); */
    padding:20px 0;
    margin-bottom:20px;
    border-bottom:10px solid rgb(255, 255, 255);
    width:80%
}
.Testimonials__container{
    width:80%;
    display:flex;
    flex-direction: column;
}
.testimonial_1, .testimonial_2{
    max-width: 50%;
    background:#757f9a;
    display: flex;
    flex-direction: column;
    padding-bottom:20px;
}
.testimonial_1{
    align-self: end;
}

.testimonial_2{
    align-self: start;
}

.testimonial_1 blockquote img{
  max-width: 80%;
  display: block; /* Makes the image a block element */
  margin-left: auto; /* Automatically adjusts the left margin */
  margin-right: auto; /* Automatically adjusts the right margin */
}

.testimonial_1 blockquote, .testimonial_2 blockquote{
    margin: 10px 10px 0;
    background: #efefef;
    padding: 20px 60px;
    position: relative;
    border: none;
    border-radius: 8px;
    font-style: italic;
    font-weight: bolder;
    font-size: 1.2vw;
  }
  
  .testimonial_1 blockquote:before,
  .testimonial_1 blockquote:after,
  .testimonial_2 blockquote:before,
  .testimonial_2 blockquote:after {
    content: "\201C";
    position: absolute;
    font-size: 80px;
    line-height: 1;
    color: #757f9a;
    font-style: normal;
  }
  
  .testimonial_1 blockquote:before, .testimonial_2 blockquote:before {
    top: 0;
    left: 10px;
  }
  
  .testimonial_1 blockquote:after, .testimonial_2 blockquote:after {
    content: "\201D";
    right: 10px;
    bottom: -0.5em;
  }
  
  .testimonial_1 div,  .testimonial_2 div {
    width: 0;
    height: 0;
  }

  .testimonial_1 div {
    border-left: 20px solid transparent;
    border-right: 0px solid transparent;
    border-top: 20px solid #efefef;
    display: flex;
    align-self: end;
    margin:0 60px 0 0;
  }

  .testimonial_2 div {
    border-left: 0px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid #efefef;
    display: flex;
    align-self: start;
    margin:0 0 0 60px;
  }
  
  .testimonial_1 p {
    color: #fff;
    text-align: left;
    display: flex;
    align-self: end;
    margin: 8px 20px 0 0px;
    font-size: 1.1vw;
  }

  .testimonial_2 p {
    color: #fff;
    text-align: left;
    display: flex;
    align-self: start;
    margin: 8px 0 0 20px;
    font-size: 1.1vw;
  }
  
  @media screen and (max-width:1440px){
    .Testimonials__header{
        font-size: 70px;
        font-weight: 900;
        padding:20px 0;
    }
    .Testimonials__container{
        width:90%;
    }

    .testimonial_1, .testimonial_2{
        max-width: 70%;
        padding-bottom:20px;
    }

    .testimonial_1 blockquote, .testimonial_2 blockquote{
        margin: 10px 10px 0;
        padding: 20px 60px;
        border-radius: 8px;
        font-weight: bolder;
        font-size: 24px;
      }
      .testimonial_1 p,  .testimonial_2 p{
        font-size: 22px;
      }
  }

  @media screen and (max-width:1024px){
    .Testimonials__header{
        font-size: 50px;
        font-weight: 900;
        padding:15px 0;
        margin-bottom:15px;
        border-bottom:8px solid rgb(255, 255, 255);
        width:90%;
    }
    .Testimonials__container{
        width:90%;
    }

    .testimonial_1, .testimonial_2{
        max-width: 80%;
        padding-bottom:15px;
    }

    .testimonial_1 blockquote, .testimonial_2 blockquote{
        margin: 10px 10px 0;
        padding: 20px 50px;
        border-radius: 8px;
        font-weight: bolder;
        font-size: 20px;
      }
      .testimonial_1 p,  .testimonial_2 p{
        font-size: 18px;
      }
  }

  @media screen and (max-width:425px){
    .Testimonials__header{
        font-size: 35px;
        font-weight: 900;
        padding:10px 0;
        margin-bottom:10px;
        border-bottom:5px solid rgb(255, 255, 255);
        width:90%;
    }
    .Testimonials__container{
        width:90%;
    }

    .testimonial_1, .testimonial_2{
        max-width: 100%;
        padding-bottom:15px;
    }

    .testimonial_1 blockquote, .testimonial_2 blockquote{
        margin: 10px 10px 0;
        padding: 20px 45px;
        border-radius: 8px;
        font-weight: bolder;
        font-size: 18px;
      }
      .testimonial_1 p,  .testimonial_2 p{
        font-size: 16px;
      }
  }