.main--container{
    background-color: #e8e7e5;
    height: 100vh;
    width: 100%;
    display:flex;
    justify-content: center;
    align-items:center;
    object-fit:contain;
    overflow:hidden;
    box-sizing: border-box;
  
}

.img--container-2{
    display:none;
    justify-content: center;
    align-items:top;
    top:0;
    height:auto;
}

.img--container > img{
    /* background: url('/public/images/img-5.jpg') center center/cover no-repeat;   */
    object-fit: cover;
    object-position:top;
    height:80vh;
}

.img--container{
    text-align: center;
    justify-content: center;
    align-items:center;
    max-height: 100vh;
    display:block;
    width:50%;
    
    box-sizing: border-box;
    float:left;
    margin: 60px 0px 60px;
    
    
}
.text--container{
    background-color: #e5e3de;
    display:grid;
    max-height:100vh;
    height: 100%;
    overflow:visible;
    position: relative;
    z-index: 2; /* Ensure the text container appears above the image container */
    padding: 1000px 150px 1000px 60px;
    align-items: center;
    align-content: center;
   
    
}

.header{
    font-family: 'Roboto', sans-serif;
    color:rgb(0, 0, 0);
    font-size: 1.2vw;
    font-weight: bold;
    border-bottom: 5px solid rgb(0, 0, 0); /* Adjust the thickness and color as needed */
    display: inline-block; /* Ensures that the border only extends as wide as the text */
    padding-bottom: 15px; /* Adjust the padding to control the distance between text and line */
    width:100%;
    margin-top:20px;
}

.quote{
    color:black;
    font-size: 6vw;
    font-weight: 1000;
    font-family:'Times New Roman', Times, serif;
    margin-top: 30px;
}

.experience{
    font-family: 'Roboto', sans-serif;
    color:#5a5454;
    font-size: 1.2vw;
    font-weight:800;
    margin-top:30px;
    border-bottom: 5px solid rgb(0, 0, 0); /* Adjust the thickness and color as needed */
    display: inline-block; /* Ensures that the border only extends as wide as the text */
    padding-bottom: 30px;
    margin-bottom: 20px;
}

@media screen and (max-height:1000px){
    .main--container{
        height: 1000px;
        min-height:850px;
    }
    
    .img--container{
        text-align: center;
        justify-content: center;
        align-self:center;
        height:auto;
        display:flex;
        width:50%;
        box-sizing: border-box;
        
        margin: 60px 0px 20px;
        padding: 30px 0px 30px;
        
    }
    .img--container > img{
        /* background: url('/public/images/img-5.jpg') center center/cover no-repeat;   */
        object-fit: cover;
        object-position:top;
        height:750px;
    }

}
@media screen and (max-width:2560px){
    .main--container{
        min-height: 100vh;
    }
    .header{
        font-size: clamp(22px,1.2vw, 100px);
    }
    
    .quote{
        font-size: clamp(90px,6vw,300px);
    }
    
    .experience{
        font-size: clamp(22px,1.2vw,100px);
    }
    .img--container{

        width:50%;
        box-sizing: border-box;
        float:left;
        margin: 60px 0px 60px;
        padding: 50px 0px 50px;
        
    }
}
@media screen and (max-width:1440px){
    .header{
        font-size: clamp(22px,1.2vw, 100px);
    }
    
    .quote{
        font-size: clamp(45px,6vw,300px);
    }
    
    .experience{
        font-size: clamp(22px,1.2vw,100px);
    }
    .text--container{
        padding: 1000px 80px 1000px 60px;
    }
}

@media screen and (max-width:1024px){
    .header{
        font-size: clamp(22px,1.2vw, 100px);
    }
    
    .quote{
        font-size: clamp(45px,6vw,300px);
    }
    
    .experience{
        font-size: clamp(22px,1.2vw,100px);
    }

    .text--container{
        padding: 1000px 60px 1000px 60px;
    }

    .img--container{
        width:70%;
        margin-left: -80px;
        margin-right: -80px;
    }
}


@media screen and (max-width:768px){
    .header{
        font-size: clamp(18px,1.2vw, 100px);
    }
    
    .quote{
        font-size: clamp(30px,6vw,300px);
    }
    
    .experience{
        font-size: clamp(18px,1.2vw,100px);
    }

    .text--container{
        padding: 1000px 20px 1000px 20px;
    }

    .img--container{
        width:85%;
        margin-left: -80px;
        margin-right: -80px;
    }
}

@media screen and (max-width:425px){

    .main--container{
        display:flex;
        flex-direction: column;
        height:1000px;
        min-height: 1000px;
        justify-content: flex-start;
    }
    .img--container-2{
        
        display:flex;
        z-index:100;
        padding:40px 0px 20px;
        
    }
    .img--container-2>img{
        object-fit: cover;
        object-position:top;
        width:60%;
        margin:auto;
        
    }

    .img--container{
        display:none;
    }
    .text--container{
        overflow: hidden;
        display: flex;
        flex-direction: column;
        padding: 0px 20px 0px 20px;
        justify-content: space-evenly;
        min-height: 600px;
    }
    .header{
        margin-top: 10vw;
    }
    .quote{
        font-size: clamp(40px,6vw,300px);
    }
    
    .experience{
        font-size: clamp(20px,1.2vw,100px);
        
    }
}

@media screen and (max-width:320px){
    .header{
        margin-top: 0px;
    }
    .quote{
        font-size: clamp(35px,6vw,300px);
        margin-top:20px;
    }
    
    .experience{
        font-size: clamp(18px,1.2vw,100px);
        margin-top:20px;
    }
}