* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

body,html {
  width: 100%;
  margin: 0;
  padding: 0;
}

.whatsapp_float {
  position: fixed;
  width: 15vw;
  height: 3vw;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #FFF;
  border-radius: 50px;
  text-align: left;
  box-shadow: 2px 2px 3px #999;
  align-items: center;
  z-index: 100;
  display:flex;
  text-decoration: none ;
  justify-content: center;
}
.whatsapp-text{
  margin-left: 18px;
  font-size: 1.6vw ;
}
.whatsapp-icon {
  font-size: 2vw;
  
}
@media screen and (max-width: 2560px) {
  .whatsapp_float{
    width: 350px;
    height: 80px;
  }
  .whatsapp-text{
    margin-left: 12px;
    font-size: 40px ;
    
  }
  .whatsapp-icon {
    font-size: 55px;
  }
}
@media screen and (max-width: 1440px) {
  .whatsapp_float{
    width: 300px;
    height: 70px;
  }
  .whatsapp-text{
    margin-left: 12px;
    font-size: 35px ;
    
  }
  .whatsapp-icon {
    font-size: 50px;
  }
}
@media screen and (max-width: 1024px) {
  .whatsapp_float{
    width: 250px;
    height: 60px;
  }
  .whatsapp-text{
    margin-left: 12px;
    font-size: 30px ;
    
  }
  .whatsapp-icon {
    font-size: 45px;
  }
}
/* for mobile */
@media screen and (max-width: 767px) {
  .whatsapp-icon {
      margin-top: 10px;
  }

  .whatsapp_float {
      width: 80px;
      height: 80px;
      bottom: 20px;
      right: 10px;
      font-size: 22px;
      padding-left: 2px;
      padding-bottom: 7px;
      text-align: center;
  }
  .whatsapp-text{
    display: none;
  }
}

@media screen and (max-width: 425px) {
  .whatsapp-icon {
      margin-top: 10px;
      font-size: 35px;
  }

  .whatsapp_float {
      width: 60px;
      height: 60px;
      bottom: 20px;
      right: 10px;
      font-size: 22px;
      padding-left: 1px;
      padding-bottom: 5px;
      text-align: center;
  }

}

@media screen and (max-width: 320px) {
  .whatsapp-icon {
      margin-top: 6px;
      font-size: 25px;
  }

  .whatsapp_float {
      width: 40px;
      height: 40px;
      bottom: 20px;
      right: 10px;
      font-size: 22px;
      padding-left: 1px;
      padding-bottom: 5px;
      text-align: center;
  }

}