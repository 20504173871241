*{
    box-sizing: border-box;
}

.team__MainContainer{
    background-color: #e8e7e5;
    min-height: 1300px;
    width: 100%;
    display:flex;
    /* justify-content: center; */
    align-items:center;
    flex-direction: column;

}

.team__header h1{
    font-family: Verdana, sans-serif;
    font-size: 4vw;
    margin:20px 0px;
    padding:50px;
    background-color: rgb(198, 194, 189);
    border-radius: 25px;
    
}

.team__header h1 > ion-icon{
    color:rgb(255, 207, 84);
}

.team__body{
    display: flex;
    flex-wrap: wrap;
    width:100%;
    height:100%;
    justify-content: space-evenly;
    align-items: center;
    padding:50px 0px;
    flex-grow: 1;
}

.team__card{
    display: flex;
    flex-direction: column;
    max-width: 800px;

}

.team__img img{
    object-fit: contain;
    height: 700px;
    width: 100%; 
}

.team__text{
    display:flex;
    min-width: 100%;
    min-height:70px;
    padding:20px 0px;
    border-bottom:5px solid black;
    justify-content: center;
}

.team__text h1{
    display:flex;
    justify-content: center;
    width:100%;
    align-items: center;
    text-align:center;
    font-size: 2vw;
    font-weight: 800;
    color:black;
}

.team__text a>h1{
    color: black;
}

@media screen and (max-width:2560px){
    .team__img img{
        object-fit: contain;
        height: 500px;
        width: 100%; 
    }
    .team__MainContainer{
        min-height: 1000px;
    }
    .team__header h1{
        font-size: 80px;
        margin:20px 0px;
        padding:50px;
        background-color: rgb(198, 194, 189);
        border-radius: 25px;
        
    }
}

@media screen and (max-width:1940px){
    .team__img img{
        object-fit: contain;
        height: 400px;
        width: 100%; 
    }
    .team__MainContainer{
        min-height: 800px;
    }
    .team__header h1{
        font-size: 60px;
        margin:20px 0px;
        padding:50px;
        background-color: rgb(198, 194, 189);
        border-radius: 25px;
        
    }
    .team__card{
        margin-top: 50px;
    }
}

@media screen and (max-width:1500px){
    .team__img img{
        object-fit: contain;
        height: 300px;
        width: 100%; 
    }
    .team__MainContainer{
        min-height: 700px;
    }
    .team__header h1{
        font-size: 50px;
        margin:20px 0px;
        padding:50px;
        background-color: rgb(198, 194, 189);
        border-radius: 25px;
        
    }
    .team__card{
        margin-top: 50px;
    }
}

@media screen and (max-width:1240px){
    .team__img img{
        object-fit: contain;
        height: 250px;
        width: 100%; 
    }
    .team__MainContainer{
        min-height: 500px;
    }
    .team__header h1{
        font-size: 40px;
        margin:20px 0px;
        padding:30px;
        background-color: rgb(198, 194, 189);
        border-radius: 25px; 
    }
    .team__text h1{
        display:flex;
        justify-content: center;
        width:100%;
        align-items: center;
        text-align:center;
        font-size: 26px;
        font-weight: 800;
        
    }

    .team__body{
        padding:30px 0px;
    }
    .team__card{
        margin-top: 50px;
    }
}

@media screen and (max-width:1010px){
    .team__img img{
        object-fit: contain;
        height: 200px;
        width: 100%; 
    }
    .team__MainContainer{
        min-height: 500px;
    }
    .team__header h1{
        font-size: 30px;
        margin:20px 0px;
        padding:30px;
        background-color: rgb(198, 194, 189);
        border-radius: 25px; 
    }
    .team__text h1{
        display:flex;
        justify-content:center;
        width:100%;
        align-items: center;
        text-align:center;
        font-size: 22px;
        font-weight: 800;
        
    }

    .team__body{
        padding:20px 0px 40px;
    }
    .team__card{
        margin-top: 50px;
    }
}

@media screen and (max-width:750px){
    .team__img img{
        object-fit: contain;
        height: 150px;
        width: 100%; 
    }
    .team__MainContainer{
        min-height: 400px;
    }
    .team__header h1{
        font-size: 25px;
        margin:20px 0px;
        padding:20px 10px;
        background-color: rgb(198, 194, 189);
        border-radius: 15px; 
    }
    .team__text h1{
        display:flex;
        width:100%;
        align-items: center;
        text-align:center;
        font-size: 18px;
        font-weight: 800;
    }

    .team__body{
        padding:20px 0px 80px;
    }
    .team__text{
        display:flex;
        min-width: 100%;
        min-height: auto;
        padding:10px 0px;
        border-bottom:5px solid black;
    }
    .team__card{
        margin-top: 50px;
    }
}

@media screen and (max-width:590px){
    .team__img img{
        object-fit: contain;
        height: 150px;
        width: 100%; 
    }
    .team__MainContainer{
        min-height: 400px;
    }
    .team__header h1{
        font-size: 20px;
        margin:20px 0px 0px;
        padding:20px 10px;
        background-color: rgb(198, 194, 189);
        border-radius: 15px; 
    }
    .team__text h1{
        display:flex;
        width:100%;
        align-items: center;
        text-align:center;
        font-size: 18px;
        font-weight: 800;
    }

    .team__body{
        padding:0px 0px 50px;
    }
    .team__text{
        display:flex;
        min-width: 100%;
        min-height: auto;
        padding:10px 0px;
        border-bottom:5px solid black;
    }
    .team__card{
        margin-top: 50px;
    }
}

@media screen and (max-width:480px){
    .team__img img{
        object-fit: contain;
        height: 150px;
        width: 100%; 
    }
    .team__MainContainer{
        min-height: 400px;
    }
    .team__header h1{
        font-size: 18px;
        margin:20px 10px 0px;
        padding:10px 5px;
        background-color: rgb(198, 194, 189);
        border-radius: 10px; 
        line-height: 25px;
        text-align: center;
    }
    .team__text h1{
        display:flex;
        width:100%;
        align-items: center;
        text-align:center;
        font-size: 18px;
        font-weight: 800;
    }

    .team__body{
        padding:0px 0px 50px;
    }
    .team__text{
        display:flex;
        min-width: 100%;
        min-height: auto;
        padding:10px 0px;
        border-bottom:5px solid black;
    }
    .team__card{
        margin-top: 30px;
    }
}

@media screen and (max-width:390px){
    .team__img img{
        object-fit: contain;
        height: 75vw;
        width: 100%; 
    }
    .team__MainContainer{
        min-height: 400px;
    }
    .team__header h1{
        font-size: 18px;
        margin:20px 10px 0px;
        padding:10px 5px;
        background-color: rgb(198, 194, 189);
        border-radius: 10px; 
        line-height: 25px;
        text-align: center;
    }
    .team__text h1{
        display:flex;
        width:100%;
        align-items: center;
        text-align:center;
        font-size: 18px;
        font-weight: 800;
    }

    .team__body{
        padding:0px 0px 50px;
    }
    .team__text{
        display:flex;
        min-width: 100%;
        min-height: auto;
        padding:10px 0px;
        border-bottom:5px solid black;
    }
    .team__card{
        margin-top: 30px;
    }
}