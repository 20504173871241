@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");


.footer {
  position: relative;
  width: 100%;
  background:rgb(174, 173, 173);
  min-height: 300px;
  padding: 20px 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 101;
}

.social-icon,
.menu {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  flex-wrap: wrap;
  
}

.social-icon__item,
.menu__item {
  list-style: none;
}

a{
    text-decoration: none;
}
.social-icon__link {
  margin: 0 30px;
  display: flex;
  transition: 0.5s;
  font-size: 4rem;
  color: #fff;
}
.social-icon__link:hover {
  transform: translateY(-10px);
}

.menu__link {
  font-size: 1.5rem;
  color: #fff;
  margin: 0 20px;
  display: inline-block;
  transition: 0.5s;
  text-decoration: none;
  
  font-weight: 300;
}

.menu__link:hover {
  color: black;
}

.footer p {
  color: #ffffff;
  margin: 15px 0 10px 0;
  font-size: 1.2rem;
  font-weight: 300;
}

@media screen and (max-width:560px) {

    .footer {

        min-height: 250px;
        padding: 20px 50px;
      }

      .social-icon__link {
        margin: 0 30px;
        display: flex;
        transition: 0.5s;
        font-size: 3rem;
        color: #fff;
      }

      .menu__link {
        font-size: 1.3rem;
        color: #fff;
        margin: 0 15px;
        display: inline-block;
        transition: 0.5s;
        text-decoration: none;
        padding:10px 0px;
        font-weight: 300;
      }
      
      .menu__link:hover {
        color: black;
      }
      
      .footer p {
        color: #ffffff;
        margin: 15px 0 10px 0;
        font-size: 1.0rem;
        font-weight: 300;
      }
}
@media screen and (max-width:448px) {

    .footer {
        min-width:100vw;
        min-height: 250px;
        padding: 20px 50px;
      }

      .social-icon__link {
        margin: 0 30px;
        display: flex;
        transition: 0.5s;
        font-size: 3rem;
        color: #fff;
      }

      .menu__link {
        font-size: 1.1rem;
        color: #fff;
        margin: 0 15px;
        display: inline-block;
        transition: 0.5s;
        text-decoration: none;
        
        font-weight: 300;
      }
      
      .menu__link:hover {
        color: black;
      }
      .menu{
        min-width:100vw;
      }
      .menu__item{
        min-width: 15vw;
      }
      .footer p {
        color: #ffffff;
        margin: 15px 0 10px 0;
        font-size: 0.8rem;
        font-weight: 300;
        min-width:100vw;
        text-align: center;
      }
}

@media  screen and (max-width:315px) {
    .social-icon__item{
        margin-top:15px
    }
    .footer{
        padding-top:0px;
    }
}
.wave {
  position: absolute;
  top: -100px;
  left: 0;
  width: 100%;
  height: 100px;
  background: url("https://i.ibb.co/wQZVxxk/wave.png");
  background-size: 1000px 100px;
}

.wave#wave1 {
  z-index: 1000;
  opacity: 1;
  bottom: 0;
  animation: animateWaves 5s linear infinite;
}

.wave#wave2 {
  z-index: 999;
  opacity: 0.5;
  bottom: 10px;
  animation: animate 5s linear infinite !important;
}

.wave#wave3 {
  z-index: 1000;
  opacity: 0.2;
  bottom: 15px;
  animation: animateWaves 4s linear infinite;
}

.wave#wave4 {
  z-index: 999;
  opacity: 0.7;
  bottom: 20px;
  animation: animate 4s linear infinite;
}

@keyframes animateWaves {
  0% {
    background-position-x: 1000px;
  }
  100% {
    background-positon-x: 0px;
  }
}

@keyframes animate {
  0% {
    background-position-x: -1000px;
  }
  100% {
    background-positon-x: 0px;
  }
}

