
.social-button {
  margin-top: 50px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  width: 480px;
  height: 110px;
  text-decoration: none;
  border-radius: 10px;
  background:  white;
  text-align: center;
  color:#3b5999;
  }

  .social-button::after {
    content: '';
    position: absolute;
    top: -1px;
    left: 50%;
    display: block;
    width: 0;
    height: 0;
    border-radius: 10px;
    transition: 0.5s;
    background: #3b5999;
  }

  .social-button:focus,
  .social-button:hover {
    color: #fff;
    background: #3b5999;
  }

  .social-button:focus::after,
  .social-button:hover:after{
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    margin-left: calc(-50% - 1px);
  }
  
  .social-button>i,
  .social-button>svg {
    position: relative;
    z-index: 1;
    transition: 0.5s;
  }

  .social-button> i {
    font-size: 85px;
  }

  .social-button>svg {
    height: percentage(0.4);
    width: percentage(0.4);
  }


/* video {
    object-fit:cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index:-1;
} */
.bigContainer{
    height: 100vh;
    min-height: 900px;
}
.background-container{
    background: url('/public/images/img-8.jpg') center center/cover no-repeat; 
    height:100%;
    width: 100%;
    display:flex;
    align-content: center;
    justify-content: center;
    box-shadow: inset 0 0 0 1000px rgba(0,0,0,0.2);
    object-fit:contain;

}

.hero-container{
    display:flex;
    flex-direction:column;
    align-items:center;
    height:fit-content;
    margin-top: auto;
    margin-bottom: auto;
}

.hero-container > h1 {
    color: #fff;
    font-size: 180px;
    
    border-bottom: 2px solid #fff; /* Adjust the thickness and color as needed */
    display: inline-block; /* Ensures that the border only extends as wide as the text */
    padding-bottom: 5px; /* Adjust the padding to control the distance between text and line */
}

.hero-container> p{
    margin-top: 20px ;
    color: #fff;
    font-size: 90px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-weight: 800;

}


.fa-play-circle {
    margin-left: 4px;
}

@media screen and (max-width:2560px){
    
    .social-button {
        margin-top: 50px;
        width: 420px;
        height: 90px;
        }
    .social-button>i {
        font-size: 70px;
        }
    .hero-container > h1 {
        font-size: 150px;
    }
        
    .hero-container> p{
         margin-top: 20px ;
         font-size: 70px;
    }
        
}

@media screen and (max-width:1910px){
    .bigContainer{
        min-height: 800px;
    }
    .social-button {
        margin-top: 40px;
        width: 340px;
        height: 80px;
        }
    
    .social-button>i {
        font-size: 60px;
        }
    .hero-container > h1 {
        font-size: 125px;
    }
        
    .hero-container> p{
         margin-top: 20px ;
         font-size: 60px;
    }
        
}
@media screen and (max-width:1540px){

    .bigContainer{
        min-height: 700px;
    }
    .social-button {
        margin-top: 40px;
        width: 300px;
        height: 80px;
        }
        .social-button>i {
            font-size: 60px;
            }
    .hero-container > h1 {
        font-size: 100px;
    }
        
    .hero-container> p{
         margin-top: 20px ;
         font-size: 55px;
    }
}

@media screen and (max-width:1288px){
    .social-button {
        margin-top: 40px;
        width: 280px;
        height: 75px;
        }
        .social-button>i {
            font-size: 55px;
            }
    .hero-container > h1 {
        font-size: 80px;
    }
        
    .hero-container> p{
         margin-top: 20px ;
         font-size: 45px;
    }

    .background-container{
        min-height: 600px;
    }
}
@media screen and (max-width:1024px){
    .bigContainer{
        min-height: 500px;
    }
    .social-button {
        margin-top: 30px;
        width: 250px;
        height: 70px;
        }
        .social-button>i {
            font-size: 48px;
            }
    .hero-container > h1 {
        font-size: 65px;
    }
        
    .hero-container> p{
         margin-top: 20px ;
         font-size: 40px;
    }
}

@media screen and (max-width:768px){
    .bigContainer{
        min-height: 400px;
    }
    .social-button {
        margin-top: 30px;
        width:clamp(100px,35vw, 250px);
        height:clamp(30px,10vw,70px);
        }
        .social-button>i {
            font-size: clamp(25px,8vw,48px);
          }
    .hero-container > h1 {
        font-size: 7.5vw;
    }
        
    .hero-container> p{
         margin-top: 20px ;
         font-size: 4.5vw;
    }
}

@media screen and (max-width:640px){

    .hero-container > h1 {    
        margin-top:50px;
    }
}

@media screen and (max-width:475px){
    .bigContainer{
        min-height: 300px;
    }
    .hero-container > h1 {
        margin-top:0px;
        font-weight: 1000;
        text-align: center;
    }

    .hero-container> p{
        text-align: center;
    }

    .social-button{
        margin-top: 20px;
    }
}

@media screen and (max-width:340px){
    .hero-container{
        width:90%
    }

    .hero-container > h1 {
        font-size: 30px;
        margin-top:0px;
        font-weight: 1000;
        text-align: center;
    }

    .hero-container> p{
        font-size: 20px; 
        text-align: center;
    }


}