.backgroundContainer{
    background: url('/public/images/img-1.jpg') center center/cover no-repeat; 
    width: 100%;
    object-fit:contain;
    box-shadow: inset 0 0 0 1000px rgba(0,0,0,0.4);
    min-height: 1000px;
    display: flex;
    justify-content: center;
}

.servicesContainer{
    width:90%;
    display: flex;
    align-items: center;
    justify-content: center;
}
/* 
// --------------
// General Layout
// -------------- */

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Maven+Pro:wght@800&display=swap");

html {
  height: 100%;
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
body {
  font-family: "Poppins", sans-serif;
  /* background: var(--bg1);
  min-height: 100vh;
  color: #444; 
  display: grid;
  place-items: center;
  padding: 3rem 1rem 10em;*/ 
}
:root {
    --font-size: 0.8em;
  
    --bg1: #f5f8f7;
    --blue: #3498db;
    --green: #2ecc71;
    --purple: 9b59b6#;
    --gold: #f1c40f;
    --red: #e74c3c;
    --orange: #e67e22;
    --shadow1: 0 2px 4px #00000026, 0 3px 6px #0000001f;
    --shadow2: 0 2px 6px #00000044, 0 4px 7px #00000022;
  }

.infoGraphic {
    width:100%;
    
    font-size:0.8em;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 4em;
}

.listItem {
      position: relative;
      height:30em;
      width: 100%;
      max-width: 25em;
      background: #f5f8f7;
      border-radius: 0.5em;
      padding: 0.5em;
      z-index: 1;
      transition: all 0.2s;
}
      
      
.numberWrap {
  position: absolute;
  }

.number {
    font-family: "maven pro", sans-serif;
    font-size: 13em;
    font-weight: 900;
    width: 0.9em;
    text-align: center;
    }

.fontColor1 {
      color: #3498db;
    }
.fontColor2 {
      color: #2ecc71;
    }
.fontColor3 {
    color: var(--purple);
    }
.fontColor4 {
      color: var(--gold);
    }
.fontColor5 {
      color: var(--red);
    }
.fontColor6 {
      color: var(--orange);
    }

    .coverWrap {
      transform: rotate(130deg);
      position: absolute;
      width: 18em;
      height: 15em;
      left: -2em;
      top: -0.5em;
    }   
    .numberCover {
      position: absolute;
      background: var(--bg1);
      width: 18em;
      height: 6em;
      border-radius: 50% 50% 0 0;
      border-bottom: 3px solid #f5f8f7;
      transition: all 0.4s;
    }
    .numberCover::before {
      position: absolute;
      content: "";
      bottom: 5px;
      left: 4em;
      right: 4em;
      top: 5em;
      box-shadow: 0 0 30px 17px #48668577;
      border-radius: 100px / 10px;
      z-index: -1;
    }

    .numberCover::after {
        position: absolute;
        bottom: 0;
        content: "";
        left: -10%;
        width: 120%;
        height: 150%;
        background: radial-gradient(
          at bottom,
          #48668533,
          transparent,
          transparent
        );
        z-index: 1;
     }
      
      .content {
        margin: 11em 3em 1em 7em;
        position: relative;
      }

      .content>h2 {
        font-size: 2.3em;
        font-weight: 800;
        text-align: center;
        margin-bottom: 18px;
        text-transform: uppercase;
      }
      .content>p {
          width:11em;
        translate: -2.5em;
        font-size: 1.8em;
        line-height: 1.4em;
      }
    
  
  
  /* // I like the curved version too, so I made it a hover for fun. */
   .coverWrap, .numberCover {
    border-radius: 100%;
  }
  
/*   
  /////////////////////////////////////
  // Fontawesome Icons
  // List: http://astronautweb.co/snippet/font-awesome/ */
  
  .icon {
    position: absolute;
    font-size: 3rem;
    text-align: center;
    top: -1.3em;
    left: 50%;
    transform: translatex(-50%);
  }

  .icon:before {
    color: #666;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
  }
  
  .iconSearch:before {
      content: "\f002";
    }
   
    .iconSocial:before {
      content: "\f08a";
    }
  
    .iconBarChart:before {
      content: "\f080";
    }
   
    .iconHome:before {
      content: "\f015";
    }
  
    .iconMoney:before {
      content: "\f155";
    }
 
    .iconCommunicate:before {
      content: "\f0e6";
    }
  
  
  @media screen and (max-width:2010px) {
    .infoGraphic {
        justify-content: center;
        gap: 4em;      
    }
    .listItem{
      margin:0px 50px;
  }
  }

  @media screen and (max-width:1516px) {
    .infoGraphic {
        justify-content: center;
        gap: 4em;
    }
    .listItem{
      margin:0px ;
  }
  }
 
  @media screen and (max-width:1190px) {
    .infoGraphic {
      justify-content: center;
      gap: 3em;
    }
    .listItem{
      height:25em;
      max-width: 24em;
      padding: 0.4em;
    }
    .number{
      font-size: 11em;
      font-weight: 900;
      width: 0.9em;
    }
    .coverWrap{
      width: 18em;
      height: 15em;
      left: -3.0em;
      top: -1.7em;
    }
    .content {
      margin: 9em 3em 1em 9em;
      position: relative;
    }
    .content>h2 {
      font-size: 2.3em;
      font-weight: 800;
      text-align: center;
      margin-bottom: 10px;
      text-transform: uppercase;
    }
    .content>p {
        width:11.3em;
      translate: -4em;
      font-size: 1.8em;
      line-height: 1.2em;
    }
  }

  @media screen and (max-width:1110px) {
    .backgroundContainer{
        padding:40px 0px;
    }
    .infoGraphic {
        justify-content: center;
        gap: 4em;
    }
    .listItem{
      margin:0px ;
     }
  }


  @media screen and (max-width:750px) {
    .infoGraphic {
        justify-content: center;
        gap: 3em;
      }
    .listItem{
      height:15em;
      max-width: 85vw;
      padding: 0.4em;
    }
    .number{
      font-size: 11em;
      font-weight: 900;
      width: 0.9em;
    }
    .coverWrap{
      width: 18em;
      height: 15em;
      left: -3.0em;
      top: -1.3em;
    }
    .numberCover {
      position: absolute;
      background: var(--bg1);
      width: 15.5em;
      height: 6em;
      border-bottom: 3px solid #f5f8f7;
      transition: all 0.4s;
    }    
    .numberCover::before {
      position: absolute;
      content: "";
      bottom: 5px;
      left: 4em;
      right: 4em;
      top: 5em;
      box-shadow: 0 0 30px 17px #48668577;
      border-radius: 100px / 10px;
      z-index: -1;
    }
    .numberCover::after {
      position: absolute;
      bottom: 0;
      content: "";
      left: -10%;
      width: 120%;
      height: 150%;
      background: radial-gradient(
        at bottom,
        #48668533,
        transparent,
        transparent
      );
      z-index: 1;
    }
    .content {
      margin: 8em 0em 1em 0em;
      position: relative;
    }
    .content>h2 {
      margin-left:18%;
      font-size: 2.3em;
      font-weight: 800;
      text-align: left;
      margin-bottom: 10px;
      text-transform: uppercase;
      }
    .content> p {
      width:11.2em;
      transform: translateY(-130px);
      translate: 11.2em;
      font-size: 2.0em;
      line-height: 1.2em;
      }
    .icon {
      position: absolute;
      font-size: 3rem;
      text-align: center;
      top: -1.3em;
      left: 30%;
      transform: translatex(-50%); 
    }                
    .icon:before {
      color: #666;
      font-family: FontAwesome;
      font-style: normal;
      font-weight: normal;
      text-decoration: inherit;
    }    
  }

  @media screen and (max-width:700px) {
    .infoGraphic {
        justify-content: center;
        gap: 3em;
      }
    .listItem{
      height:20em;
      max-width: 85vw;
      padding: 0.2em;
    }
    .number{
      font-size: 11em;
      font-weight: 900;
      width: 0.9em;
    }
    .coverWrap{
      width: 18em;
      height: 15em;
      left: -3.0em;
      top: -1.3em;
    }
    .numberCover {
      position: absolute;
      background: var(--bg1);
      width: 15.5em;
      height: 6em;
      border-bottom: 3px solid #f5f8f7;
      transition: all 0.4s;
    }    
    .numberCover::before {
      position: absolute;
      content: "";
      bottom: 5px;
      left: 4em;
      right: 4em;
      top: 5em;
      box-shadow: 0 0 30px 17px #48668577;
      border-radius: 100px / 10px;
      z-index: -1;
    }
    .numberCover::after {
      position: absolute;
      bottom: 0;
      content: "";
      left: -10%;
      width: 120%;
      height: 150%;
      background: radial-gradient(
        at bottom,
        #48668533,
        transparent,
        transparent
      );
      z-index: 1;
    }
    .content {
      margin: 6.5em 1em 1em 7em;
      position: relative;
      justify-content: center;
    }
    .content>h2 {
      margin-left:0%;
      font-size: 2.3em;
      font-weight: 800;
      text-align: center;
      margin-bottom: 10px;
      text-transform: uppercase;
      }
    .content> p {
      transform: translateY(-0px);
      width:100%;
      translate: -0em;
      font-size: 1.8em;
      line-height: 1.2em;
      }
    .icon {
      position: absolute;
      font-size: 3rem;
      text-align: center;
      top: -1.3em;
      left: 50%;
      transform: translatex(-50%); 
    }                
    .icon:before {
      color: #666;
      font-family: FontAwesome;
      font-style: normal;
      font-weight: normal;
      text-decoration: inherit;
    }    
  }

  @media screen and (max-width:496px) {
    .content {
      margin: 6.5em 1em 1em 7em;
      position: relative;
      justify-content: center;
    }
    .content>h2 {
      margin-left:0%;
      font-size: 2.1em;
      font-weight: 800;
      text-align: center;
      margin-bottom: 10px;
      text-transform: uppercase;
      }
    .content> p {
      transform: translateY(-0px);
      width:100%;
      translate: -0em;
      font-size: 1.6em;
      line-height: 1.2em;
      }
    .icon {
      position: absolute;
      font-size: 3rem;
      text-align: center;
      top: -1.3em;
      left: 50%;
      transform: translatex(-50%); 
    }                
    .icon:before {
      color: #666;
      font-family: FontAwesome;
      font-style: normal;
      font-weight: normal;
      text-decoration: inherit;
    }    
  }

  @media screen and (max-width:385px) {
    .content {
      margin: 6.5em 1em 1em 7em;
      position: relative;
      justify-content: center;
    }
    .content>h2 {
      margin-left:0%;
      font-size: 2.1em;
      font-weight: 800;
      text-align: center;
      margin-bottom: 5px;
      text-transform: uppercase;
      }
    .content> p {
      transform: translateY(-0px);
      width:100%;
      translate: -0em;
      font-size: 1.5em;
      line-height: 1.1em;
      }
    .icon {
      position: absolute;
      font-size: 3rem;
      text-align: center;
      top: -1.3em;
      left: 50%;
      transform: translatex(-50%); 
    }                
    .icon:before {
      color: #666;
      font-family: FontAwesome;
      font-style: normal;
      font-weight: normal;
      text-decoration: inherit;
    }    
  }

  @media screen and (max-width:340px) {
    .infoGraphic {
        gap: 2em;
      }
    .listItem{
      height:21em;
    }
    .number{
      font-size: 8em;
      font-weight: 900;
      width: 0.9em;
    }
    .coverWrap{
      width: 18em;
      height: 15em;
      left: -6.0em;
      top: -1.3em;
    }
    .content {
      margin: 6em 1em 1em 6em;
      position: relative;
      justify-content: center;
    }
    .content>h2 {
      margin-left:0%;
      font-size: 1.6em;
      font-weight: 800;
      text-align: center;
      margin-bottom: 40px;
      text-transform: uppercase;
      }
    .content> p {
      transform: translateY(-0px);
      width:11.5em;
      height:5em;
      translate: -3em;
      font-size: 1.5em;
      line-height: 1.1em;
      }
    .icon {
      position: absolute;
      font-size: 2.5rem;
      text-align: center;
      top: -1.2em;
      left: 50%;
      transform: translatex(-50%); 
    }                
    .icon:before {
      color: #666;
      font-family: FontAwesome;
      font-style: normal;
      font-weight: normal;
      text-decoration: inherit;
    }    
  }

  @media screen and (max-width:294px) {
    .infoGraphic {
        gap: 2em;
      }
    .listItem{
      height:22em;
    }
    .content {
      margin: 6em 1em 1em 6em;
      position: relative;
      justify-content: center;
    }
    .content>h2 {
      margin-left:0%;
      font-size: 1.6em;
      font-weight: 800;
      text-align: center;
      margin-bottom: 40px;
      text-transform: uppercase;
      }
    .content> p {
      transform: translateY(-0px);
      width:10em;
      height:5em;
      translate: -3em;
      font-size: 1.5em;
      line-height: 1.1em;
      } 
  }
  @media screen and (max-width:260px) {
    .infoGraphic {
        gap: 2em;
      }
    .listItem{
      height:24em;
    }
    .content {
      margin: 6em 1em 1em 6em;
      position: relative;
      justify-content: center;
    }
    .content>h2 {
      margin-left:0%;
      font-size: 1.6em;
      font-weight: 800;
      text-align: center;
      margin-bottom: 40px;
      text-transform: uppercase;
      }
    .content> p {
      transform: translateY(-0px);
      width:9em;
      height:5em;
      translate: -3em;
      font-size: 1.5em;
      line-height: 1.1em;
      } 
  }
  @media screen and (max-width:240px) {
    .infoGraphic {
        gap: 2em;
        overflow: hidden;
      }
    .listItem{
      height:24em;
    }
    .coverWrap{
      display: none;
      width: 90vw;
      height: 90vw;
      left: -30.0vw;
      top: -20vw;
      overflow: hidden;
  }
    .content {
      margin: 6em 1em 1em 6em;
      position: relative;
      justify-content: center;
    }
    .content>h2 {
      margin-left: 0%;
      font-size: 8vw;
      font-weight: 800;
      text-align: center;
      margin-bottom: 40px;
      text-transform: uppercase;
      overflow: hidden;
      }
    .content> p {
      transform: translateY(-0px);
      width:70vw;
      height:30em;
      translate: -3em;
      font-size: 1.5em;
      line-height: 1.1em;
      overflow: hidden;
      } 
  }
  @media screen and (max-width:210px) {
    .infoGraphic {
        gap: 2em;
        overflow: hidden;
      }
    .listItem{
      height:24em;
    }
    .coverWrap{
      display: none;
      width: 90vw;
      height: 90vw;
      left: -30.0vw;
      top: -20vw;
      overflow: hidden;
  }
    .content {
      margin: 6em 1em 1em 6em;
      position: relative;
      justify-content: center;
    }
    .content>h2 {
      margin-left: 0%;
      font-size: 8vw;
      font-weight: 800;
      text-align: center;
      margin-bottom: 40px;
      text-transform: uppercase;
      overflow: hidden;
      }
    .content> p {
      transform: translateY(-0px);
      width:60vw;
      height:30em;
      translate: -4em;
      font-size: 1.2em;
      line-height: 1.1em;
      overflow: hidden;
      } 
  }
