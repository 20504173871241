.navbar {
    background:rgb(0,0,0, 0.8);
    min-height:60px;
    max-height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 999;
    
  }
  
  .logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
  }

  .navbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 30px;
  }
  
  .navbar-logo {
    color: #fff;
    justify-self: start;
    cursor: pointer;
    text-decoration: none;
    font-size: 1.4rem;
    display: flex;
    align-items: center;
    font-family: 'Roboto', sans-serif;
    margin-top: 10px;
    transition: color 0.5s ease;
  }
  
  .navbar-logo.scrolled{
    color: #7a7171;
  }

  .fa-typo3 {
    margin-left: 0.5rem;
    font-size: 1.8rem;
  }
  
  .nav-menu {
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 15px;
    list-style: none;
    text-align: center;
    width: 60vw;
    justify-content: end;
    margin-right: 2rem;
  }
  
  .nav-item {
    height: 10vh;
  }
  
  .nav-links {
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
    font-size: 25px;
  }
  
  .nav-links:hover {
    border-bottom: 4px solid #fff;
    transition: all 0.2s ease-out;
  }
  
  .fa-bars {
    color: #fff;
  }
  .fa-bars.scrolled {
    color: #7a7171;
  }
  .nav-links-mobile {
    display: none;
  }
  
  .menu-icon {
    display: none;
  }
  @media screen and (max-width: 1150px) {
    .navbar-logo {
      font-size: 1.2rem;
    }

  }
  @media screen and (max-width: 1040px) {
    .NavbarItems {
      position: relative;
    }
    .navbar{
      min-height: 0px;
      
    }
    /* .navbar{
      min-height: 70px;
      opacity: 0.8;
    } */
    .navbar-logo {
      margin-top: 17px;
    }
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      min-height: 45vh;
      position: absolute;
      top: 80px;
      left: -100%;
      opacity:1;
      transition: all 0.5s ease;
    }

    .nav-menu.active {
      background: #242222;
      left: 0;
      opacity: 0.9;
      transition: all 0.5s ease;
      z-index: 1;
      
    }
  
    .nav-links {
      justify-content: center;
      padding: 2rem;
      width: 100%;
      display: flex;
      align-items: center;
    }
  
    .nav-links:hover {
      background-color: #fff;
      color: #242424;
      border-radius: 0;
    }
  
    .navbar-logo {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(25%, 50%);
      overflow: hidden;
    }
    
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }
  
    .fa-times {
      color: #fff;
      font-size: 2rem;
    }
  
    .fa-times.scrolled{
      color: #7a7171;
    }
    .nav-links-mobile {
      display: block;
      text-align: center;
      
      border-radius: 4px;
      width: 80%;
      text-decoration: none;
      font-size: 1.5rem;
      background-color: transparent;
      color: #fff;
      padding: 14px 20px;
      border: 1px solid #fff;
      transition: all 0.3s ease-out;
      margin: auto;
    }
    
    .nav-links-mobile:hover {
      background: #fff;
      color: #242424;
      transition: 250ms;
    }
  }

  @media screen and (max-width: 365px) {
    .logo-container{
      margin-left:0px;
    }

    .navbar-logo {

      font-size: 1.0rem;
      width:40%;
      margin:0px;
    }

    .nav-links-mobile {
      font-size: 1.2rem;
    }

    .nav-links{
      font-size: 1.2rem;
    }
  }

  @media screen and (max-height: 700px) {

    .nav-menu{
      top:60px;
      
    }
    .nav-item{
      margin:0px;
      top: 0;
      
    }

    .nav-links-mobile {
      font-size: 1.2rem;
      padding:0;
      margin: 0px auto;
    }

    .nav-links{
      font-size: 1.2rem;
      padding:0.2rem;
      text-align: center;
    }
    .nav-menu.active{
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      
    }
  }