.DisplayFrame {
    touch-action:none;
    width:70vw;
    height:calc(70vw *0.5625);

    margin:auto;
    text-align:center;
    background-color: transparent;
    overflow-y:hidden;
    overflow-x:hidden;
    white-space:nowrap;
    z-index:1;
    caret-color: transparent;
    
}

.mainContainer{
    display:flex;
    
    justify-content: space-between;
}

.CarouContainer{
    display:flex;
    flex-direction: column;
    flex:1;
}

.DisplayFrame::-webskit-scrollbar{
    display:none;
}

.LeftArrow{
    position:absolute;
    left:1%;
    padding-left: 2px;
    padding-right: 2px;
    font-size:100px;
    color:#7f8c98;
    user-select:none;
    z-index:2;
}

.LeftArrow:hover{
    color:#bbccdd;
}

.LeftArrow:active{
    color:#206ebd;
}

.RightArrow{
    position:absolute;
    right:1%;
    padding-left: 2px;
    padding-right: 2px;
    font-size:100px;
    color:#7f8c98;
    user-select:none;
    z-index:2;
}

.RightArrow:hover{
    color:#bbccdd;
}

.RightArrow:active{
    color:#206ebd;
}

.Container2{
    position:relative;
    display:flex;
    
    vertical-align: middle;
    overflow:hidden;
    width:100%;
    
    align-items: center;
}

.recordText{
    width:30%;
    position:relative;
    display: flex;
    font-size: 2.5vw;
    background-color: #f0d9d9;
    /* background-color:#485a6e */
    color:rgb(112, 39, 39);
    justify-content: center;
    padding:0px 30px 0px 30px;
    z-index: 4;
    text-align: center;
    align-items: center;
    border:10px solid rgb(163, 154, 154);
    
}

.CarouselItem{
    touch-action:none;
    height:100%;
    width:100%;
    display:inline-block;
    vertical-align:top;
    margin-right: 0.1%;
    transition:all 500ms ease-out;
}

.FastAnimation{
    transition:all 50ms linear;
}

.CarouselItem img{
    height:100%;
    max-width:100%;
    background-color: none;
}

.Navigation{
    width:90%;
    max-width: calc(85vh *1.78);
    justify-content:center;
    display:flex;
    margin: 0.5% auto;
    user-select:none;
}

.PositionIndicator{
    box-sizing:border-box;
    width:1.5vw;
    height:1.5vw;
    border: 3px solid #aabbcc;
    border-radius:50%;
    margin:5px;

}

.PositionIndicator:hover{
    background-color: #591212;
}

.PositionIndicator:active{
    background-color: #183552;
}

.CurrentPosition{
    border:0px solid #591212;
    background: #591212;
}

@media (max-width:600px){
    .PositionIndicator{
        width:1.5vh;
        height:1.5vh;
    }

}

@media (max-width:1850px){

    .recordText{
        width:50%;
        font-size: 3vw;
        padding:0px 30px 0px 30px; 
    }
    .DisplayFrame {
        width:65vw;
        height:calc(80vw *0.5625);
       
    }
}

@media (max-width:1050px){
    .recordText{

        padding:0px 20px 0px 20px; 
    }
    .LeftArrow, .RightArrow{
        padding-left: 2px;
        padding-right: 2px;
        font-size:70px;
    }
    .DisplayFrame {
        width:65vw;
        height:calc(80vw *0.5625);
       
    }
}

@media (max-width:768px){

    .recordText{
        
        padding:0px 10px 0px 10px; 
    }
    .LeftArrow, .RightArrow{
        padding-left: 2px;
        padding-right: 2px;
        font-size:50px;
    }

    .DisplayFrame {
        width:65vw;
        height:calc(80vw *0.5625);
       
    }
}

@media (max-width:425px){

    .mainContainer{
        flex-direction: column;
        justify-content:space-between;
    }
    .recordText{
        min-width:100vw;
        padding:15px;
        font-size: 20px;
        text-align: center;
        border:5px solid rgb(163, 154, 154);

    }
    .LeftArrow, .RightArrow{
        padding-left: 2px;
        padding-right: 2px;
        font-size:35px;
    }

    .DisplayFrame {
        min-width:100px;
        height:calc(90vw *0.5625);
        margin-top: 15px;

    }
}

