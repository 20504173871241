.video-background {
    
    object-fit:cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index:-1;
    top: 0;
    left: 0;
}

.record-container{
    max-height: 100vh;
    width: 100%;
    display:flex;
    flex-flow: column;
    justify-content: space-evenly;
    align-items:center;
    object-fit:contain;
    overflow:hidden;
    background-color: #e5e3de;
    /* background: url('/public/images/img-7.jpg') center center/cover no-repeat;  */
    background-attachment: fixed;
    /* box-shadow: inset 0 0 0 1000px rgba(0,0,0,0.4); */
    padding-top:20px;
    min-height: 1500px;
}

.record-header{
    text-align:left;
    margin: 0px 0px 0px 0px;
    width:95%;
    border-bottom: 10px solid  rgb(174, 173, 173); /* Adjust the thickness and color as needed */
    display: inline-block; /* Ensures that the border only extends as wide as the text */
    padding-bottom: 15px; /* Adjust the padding to control the distance between text and line */
}
.record-header>p {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 90px;
    font-weight: 1000;
    margin:0px 0px 10px;
    color:black;
    
    
}
@media (max-width:2900px){

    .record-container{
        min-height: 1400px;
    }
  
}
@media (max-width:2560px){

    .record-container{
        min-height: 1300px;
    }
  
}

@media (max-width:2230px){

    .record-container{
        min-height: 1200px;
    }
  
}
@media (max-width:2010px){

    .record-container{
        min-height: 1100px;
    }
  
}
@media (max-width:1850px){

    .record-header>p{
        font-size: 4vw;
        margin:20px 0px 10px;

    }
    .record-container{
        min-height: 1050px;
    }
    
}

@media (max-width:1610px){

    .record-container{
        min-height: 900px;
    }
  
}
@media (max-width:1300px){

    .record-container{
        min-height: 800px;
    }
  
}
@media (max-width:1050px){
    .record-header{
        border-bottom: 5px solid  rgb(174, 173, 173);
    }
    .record-header>p{
        font-size: 40px;
        margin:20px 0px 10px;
        
    }
    .record-container{
        min-height: 650px;
    }
}

@media (max-width:850px){

    .record-container{
        min-height: 550px;
    }
  
}

@media (max-width:700px){
    .record-header>p{
        font-size: 30px;
        margin:10px 0px 5px;
 
    }  
    .record-container{
        min-height: 450px;
    }
}

@media (max-width:550px){

    .record-container{
        min-height: 350px;
    }
  
}
@media (max-width:425px){
    .record-header{
        text-align: center; 
        padding: 0px 0px 10px;
    }

    .record-header>p{
        margin:0px;
    }
}