.cards {
  /* background: url('/public/images/img-6.jpg') no-repeat;  */
  background-color: #e8e7e5;
  min-height: 100vh;
  width: 100%;
  background-size:cover;
  background-position: center center;
  background-attachment: fixed;
  
  box-sizing: border-box;
  padding: 30px 30px 50px;
  
  }

.card__header{
  text-align: right;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 90px;
  font-weight: 900;
  color:black;
  margin-bottom:20px;
  border-bottom:10px solid rgb(174, 173, 173);
  padding-bottom:10px;

}

.cards__video{
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;

  
}
.propnex__video{
  display: flex;
  position: absolute;
  top: 0;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  height: 90%;
}


  .cards__container {
    display: flex;
    flex-flow:wrap;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    border: 20px solid  rgb(174, 173, 173);
    padding:20px;
    gap:50px;
  }
  
  .cards__wrapper {
    position: relative;
    margin: 50px 0 45px;
    
  }
  
  .cards__items {
    margin-bottom: 24px;
    margin-top: 24px;
    width:560px;
  }
  
  .cards__item {
    display: flex;
    flex: 1;
    border-radius: 10px;
  }
  
  .cards__item__link {
    display: flex;
    flex-direction: column;
    
    
    width: 100%;
    /* box-shadow: 0 6px 20px rgb(255, 255, 255); */
    /* -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(20px 6px 20px rgba(56, 125, 255, 0.017)); */
    overflow: hidden;
    text-decoration: none;
    /* background-color: white; */
    
  }
  .cards__label{
    padding: 10px 12px;
    text-align: center;
    width:100%;
    font-size: 30px;
    font-weight: 500;
    color: #ffffff;
    background-color: rgb(197, 44, 125);
    box-sizing: border-box;
    font-family:Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  }
  .cards__item__pic-wrap {
    
    position: relative;
    width: 100%;
    padding-bottom: 80%;
    overflow: hidden;
    
  }
  
  .fade-img {
    animation-name: fade-img;
    animation-duration: 2s;
  }
  
  /* .cards__item__pic-wrap::after {
    content: attr(data-category);
    position: absolute;
    top: 0;
    padding: 10px 12px;
     max-width: calc((100%) - 20px); 
    text-align: center;
    width:100%;
    font-size: 30px;
    font-weight: 500;
    color: #ffffff;
    background-color: #EE82EE;
    box-sizing: border-box;
    font-family:Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  } */
  
  .cards__item__img {
    position: absolute;
    justify-self: left;
    top: 0;
    right: 0;
    left: 0;
    display: flex;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    object-position: top;
    transition: all 0.2s linear;

  }
  
  .cards__item__img:hover {
    transform: scale(1.1);
  }
  
  .cards__item__text {
    display: flex;
    color: #000000;
    font-size: 30px;
    margin-top:20px;
    font-family: "Roboto",'sans serif';
    height:100px;
    border-bottom: 5px solid rgb(0, 0, 0); /* Adjust the thickness and color as needed */

  }
  
  @media only screen and (max-width: 1950px) {
    .cards{
      padding: 30px 30px 50px;
    }
    .card__header{
      font-size: 4vw;
      font-weight: 900;
      margin-bottom:20px;
      padding-bottom:10px;
    }
    .cards__container {
      border: 20px solid  rgb(174, 173, 173);
      padding:10px;
      gap:40px;
    }
    .cards__items {
      margin-bottom: 15px;
      margin-top: 15px;
      width:450px;
    }

    .cards__item__text {
      font-size: 27px;
      margin-top:20px;
      height:85px;
    }

    .cards__label {
      padding: 10px 12px;
      font-size: 27px;
      font-weight: 500;
    }

  }


  @media only screen and (max-width: 1560px) {
    .cards{
      padding: 30px 30px 50px;
    }
    .card__header{
    
      font-weight:900;
      margin-bottom:20px;
      padding-bottom:10px;
    }
    .cards__container {
      border: 20px solid  rgb(174, 173, 173);
      padding:10px;
      gap:30px;
    }
    .cards__items {
      margin-bottom: 15px;
      margin-top: 15px;
      width:350px;
    }

    .cards__item__text {
      font-size: 24px;
      margin-top:10px;
      height:75px;
    }

    .cards__label {
      padding: 7px 9px;
      font-size: 22px;
      font-weight: 500;
    }

  }

  @media only screen and (max-width: 1230px) {
    .cards{
      padding: 30px 20px 50px;
    }
    .card__header{

      font-weight: 900;
      margin-bottom:15px;
      padding-bottom:10px;
    }
    .cards__container {
      border: 15px solid  rgb(174, 173, 173);
      padding:10px;
      gap:20px;
    }
    .cards__items {
      margin-bottom: 10px;
      margin-top: 10px;
      width:300px;
    }

    .cards__item__text {
      font-size: 22px;
      margin-top:10px;
      height:65px;
    }

    .cards__label {
      padding: 7px 9px;
      font-size: 20px;
      font-weight: 500;
    }

  }

  @media only screen and (max-width: 1030px) {
    .cards{
      padding: 30px 20px 30px;
    }
    .card__header{
      font-size: 40px;
      font-weight: 900;
      margin-bottom:15px;
      padding-bottom:10px;
      border-bottom:5px solid rgb(174, 173, 173);
    }
    .cards__container {
      border: 12px solid  rgb(174, 173, 173);
      padding:10px;
      gap:20px;
    }
    .cards__items {
      margin-bottom: 10px;
      margin-top: 10px;
      width:250px;
    }

    .cards__item__text {
      font-size: 20px;
      margin-top:10px;
      height:60px;
      border-bottom: 3px solid rgb(0, 0, 0);
    }

    .cards__label {
      padding: 5px 7px;
      font-size: 18px;
      font-weight: 500;
    }

  }

  @media only screen and (max-width: 874px) {
    .cards{
      padding: 30px 20px 30px;
    }
    .card__header{
      
      font-weight: 900;
      margin-bottom:15px;
      padding-bottom:10px;
    }
    .cards__container {
      border: 10px solid  rgb(174, 173, 173);
      padding:10px;
      gap:20px;
    }
    .cards__items {
      margin-bottom: 10px;
      margin-top: 10px;
      width:200px;
    }

    .cards__item__text {
      font-size: 2vw;
      margin-top:10px;
      height:7vw;
    }

    .cards__label {
      padding: 4px 6px;
      font-size: 1.9vw;
      font-weight: 500;
    }

  }

  @media only screen and (max-width: 720px) {
    .cards{
      padding: 30px 20px 30px;
    }
    .card__header{
      font-size: 30px;
      font-weight: 900;
      margin-bottom:15px;
      padding-bottom:10px;
    }
    .cards__container {
      border: 10px solid  rgb(174, 173, 173);
      padding:5px;
      gap:15px;
    }
    .cards__items {
      margin-bottom: 10px;
      margin-top: 10px;
      width:180px;
    }

    .cards__item__text {
      font-size: 2vw;
      margin-top:10px;
      height:7vw;
      border-bottom: 3px solid rgb(0, 0, 0);
    }

    .cards__label {
      padding: 4px 6px;
      font-size: 1.9vw;
      font-weight: 500;
    }

  }

  @media only screen and (max-width: 640px) {
    .cards{
      padding: 30px 20px 30px;
    }
    .card__header{
      
      font-weight: 900;
      margin-bottom:15px;
      padding-bottom:10px;
    }
    .cards__container {
      border: 10px solid  rgb(174, 173, 173);
      padding:5px;
      gap:15px;
    }
    .cards__items {
      margin-bottom: 10px;
      margin-top: 10px;
      width:240px;
    }

    .cards__item__text {
      font-size: 13px;
      margin-top:10px;
      height:45px;
    }

    .cards__label{
      padding: 4px 6px;
      font-size: 13px;
      font-weight: 500;
    }

  }

  @media only screen and (max-width: 565px) {
    .cards{
      padding: 20px 10px 20px;
    }
    .card__header{
     
      font-weight: 900;
      margin-bottom:15px;
      padding-bottom:10px;
    }
    .cards__container {
      border: 8px solid  rgb(174, 173, 173);
      padding:5px;
      gap:15px;
    }
    .cards__items {
      margin-bottom: 10px;
      margin-top: 10px;
      width:200px;
    }

    .cards__item__text {
      font-size: 13px;
      margin-top:10px;
      height:45px;
    }

    .cards__label {
      padding: 4px 6px;
      font-size: 13px;
      font-weight: 500;
    }

  }

  @media only screen and (max-width: 461px) {
    .cards{
      padding: 20px 10px 20px;
    }
    .card__header{
      text-align: center;
    
      font-weight: 900;
      margin-bottom:15px;
      padding-bottom:10px;
    }
    .cards__container {
      border: 8px solid  rgb(174, 173, 173);
      padding:5px;
      gap:10px;
    }
    .cards__items {
      margin-bottom: 5px;
      margin-top: 5px;
      width:170px;
    }

    .cards__item__text {
      font-size: 13px;
      margin-top:10px;
      height:45px;
      border-bottom: 1px solid rgb(0, 0, 0);
    }

    .cards__label {
      padding: 4px 6px;
      font-size: 13px;
      font-weight: 500;
    }

  }

  @media only screen and (max-width: 396px) {
    .cards{
      padding: 20px 8px 20px;
    }
    .card__header{

      font-weight: 900;
      margin-bottom:15px;
      padding-bottom:10px;
    }
    .cards__container {
      border: 5px solid  rgb(174, 173, 173);
      padding:5px;
      gap:8px;
    }
    .cards__items {
      margin-bottom: 5px;
      margin-top: 5px;
      width:150px;
    }

    .cards__item__text {
      font-size: 12px;
      margin-top:10px;
      height:45px;
    }

    .cards__label {
      padding: 4px 6px;
      font-size: 11px;
      font-weight: 500;
    }

  }

  @media only screen and (max-width: 344px) {
    .cards{
      padding: 20px 8px 20px;
    }
    .card__header{
      overflow: hidden;
      
      font-weight: 900;
      margin-bottom:10px;

    }
    .cards__container {
      border: 5px solid  rgb(174, 173, 173);
      padding:5px;
      gap:5px;
    }
    .cards__items {
      margin-bottom: 5px;
      margin-top: 5px;
      width:130px;
    }

    .cards__item__text {
      font-size: 12px;
      margin-top:5px;
      height:55px;
    }

    .cards__label{
      padding: 2px 4px;
      font-size: 11px;
      font-weight: 500;
    }

  }

  
  @media only screen and (max-width: 300px) {
    .cards{
      padding: 20px 8px 20px;
    }
    .card__header{
      overflow: hidden;
      
      font-weight: 900;
      margin-bottom:10px;

    }
    .cards__container {
      border: 5px solid  rgb(174, 173, 173);
      padding:5px;
      gap:5px;
    }
    .cards__items {
      margin-bottom: 5px;
      margin-top: 5px;
      width:74vw;
    }

    .cards__item__text {
      font-size: 12px;
      margin-top:5px;
      height:40px;
    }

    .cards__label {
      padding: 2px 4px;
      font-size: 11px;
      font-weight: 500;
    }

  }