
.timelineMainContainer{
    --color: rgba(30, 30, 30);
    --bgColor: #e6e4db;
    min-height: fit-content;
    display: grid;
    align-content: center;
    gap: 2rem;
    font-family: "Poppins", sans-serif;
    color: var(--color);
    background: var(--bgColor);
    overflow: hidden;
    width: 100%;
}

input {
    position: absolute;
    opacity: 0;
    z-index: 30;
    overflow: hidden;
}
.timelineaccordion {
    width: 100%;
    color: white;
    overflow: hidden;
    margin-bottom: 16px;
    z-index: 30;
}
.timelineaccordion:last-child{
    margin-bottom: 0;
}
.accordion-label {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: center;
    padding: 16px;
    background: rgba(149, 15, 15, 0.8);
    align-items: center;
    height:8rem;
    font-weight: bold;
    cursor: pointer;
    font-size: 3rem;
    overflow: hidden;
}
.accordion-label:hover {
    background: rgb(186, 4, 4);
}
/* .accordion-label::after {
    content: "\276F";
    width: 16px;
    height: 16px;
    text-align: center;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
} */
.accordion-content {
    overflow: hidden;
    max-height: 0;
    padding: 0 16px;
    color: rgba(4,57,94,1);
    background: var(--bgColor);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}
.accordion-content p{
    margin: 0;
    color: rgba(4,57,94,.7);
    font-size: 18px;
}
input:checked + .accordion-label {
    background:rgb(186, 4, 4);
}
input:checked + .accordion-label::after {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}
input:checked ~ .accordion-content {
    max-height: 1000vh;
    height: fit-content;
    padding: 16px;
}


.timeline-listcontainer {
    --col-gap: 2rem;
    --row-gap: 2rem;
    --line-w: 0.5rem;
    display: grid;
    grid-template-columns: var(--line-w) 1fr;
    grid-auto-columns: max-content;
    column-gap: var(--col-gap);
    list-style: none;
    width: 90%;
    height:90%;
    margin-inline: auto;
    margin-top: 2rem;
  }
  
  /* line */
  .timeline-listcontainer::before {
    transform: translateY(-1.5rem);
    content: "";
    grid-column: 1;
    grid-row: 1 / span 20;
    background: rgb(181, 180, 180);
    border-radius: calc(var(--line-w) / 2);
  }
  
  /* columns*/
  
  /* row gaps */
  .timeline-listitem:not(:last-child) {
    margin-bottom: var(--row-gap);
  }
  
  /* card */
  .timeline-listitem {
    height:fit-content;
    grid-column: 2;
    --inlineP: 2rem;
    margin-inline: var(--inlineP);
    grid-row: span 2;
    display: grid;
    max-height: fit-content;
  }

  /* date */
  .date {
    --dateH: 4.5rem;
    height: var(--dateH);
    margin-inline: calc(var(--inlineP) * -1);
  
    text-align: center;
    background-color: var(--accent-color);
  
    color: white;
    font-size: 2.5rem;
    font-weight: 700;
  
    display: grid;
    place-content: center;
    position: relative;
  
    border-radius: calc(var(--dateH) / 2) 0 0 calc(var(--dateH) / 2);
  }
  
  /* date flap */
  .date::before {
    content: "";
    width: var(--inlineP);
    aspect-ratio: 1;
    background: var(--accent-color);
    background-image: linear-gradient(rgba(0, 0, 0, 0.2) 100%, transparent);
    position: absolute;
    top: 100%;
  
    clip-path: polygon(0 0, 100% 0, 0 100%);
    right: 0;
  }
  
  /* circle */
  .date::after {
    content: "";
    position: absolute;
    width: 3rem;
    aspect-ratio: 1;
    background: var(--bgColor);
    border: 0.5rem solid var(--accent-color);
    border-radius: 50%;
    top: 50%;
  
    transform: translate(50%, -50%);
    right: calc(100% + var(--col-gap) + var(--line-w) / 2);
  }
  
  /* title descr */
.title, .descr {
    background: var(--bgColor);
    position: relative;
    padding-inline: 1.2rem;
    font-size: 1.8rem;
    line-height: 2.5rem;
  }
.title {
    overflow: hidden;
    padding-block-start: 1.5rem;
    padding-block-end: 1rem;
    font-weight: 100;
  }
.descr {
    padding-block-end: 1rem;
    font-weight: 100;
  }
  
  /* shadows */
.title::before, .end::before {
    content: "";
    position: absolute;
    width: 95%;
    background: rgba(0, 0, 0, 0.5);
    left: 50%;
    border-radius: 50%;
    filter: blur(2px);
    transform: translate(-50%, 50%);
  }
.title::before {
    bottom: calc(100% + 0.125rem);
    height: 1rem;
  }

.end{
    background: var(--bgColor);
    position: relative;
    padding-inline:0.5rem;
    font-size: 1.5rem;
    padding-block-end: 1.5rem;
    font-weight: 100;
    height:10px;
    }
.end::before {
    z-index: 3;
    bottom: 0rem;
    height: 0.2rem;
  }
  .timeline__img{
    justify-content: center;
    display: flex;
    height:100%;
    
  }
  .timeline__img img{
    display:block;
    overflow: hidden;
    object-fit: contain;
    width: 90%;
    
    border-radius: 5px;
    padding:20px; 
  }

  @media (min-width: 40rem) {
    .timeline-listcontainer {
      grid-template-columns: 1fr var(--line-w) 1fr;
    }
    .timeline-listcontainer::before {
      grid-column: 2;
    }
    .timeline-listitem:nth-child(odd) {
      grid-column: 1;
    }
    .timeline-listitem:nth-child(even) {
      grid-column: 3;
    }
  
    /* start second card */
    .timeline-listitem:nth-child(2) {
      grid-row: 2/4;
    }
  
    .timeline-listitem:nth-child(odd) > .date::before {
      clip-path: polygon(0 0, 100% 0, 100% 100%);
      left: 0;
    }
  
    .timeline-listitem:nth-child(odd) >.date::after {
      transform: translate(-50%, -50%);
      left: calc(100% + var(--col-gap) + var(--line-w) / 2);
    }
    .timeline-listitem:nth-child(odd) > .date {
      border-radius: 0 calc(var(--dateH) / 2) calc(var(--dateH) / 2) 0;
    }
  }

  @media screen and (max-width:1900px){
    .timeline-listcontainer {
        width: 95%;
      }
    .date {
        --dateH: 3.5rem;
        height: var(--dateH);
        font-size: 2.2rem;
        font-weight: 700;
    }
    /* Date flap */
    .timeline-listitem {
        --inlineP: 2rem;
      }
    /* circle */
    .date::after {
        width: 3rem;
        border: 0.5rem solid var(--accent-color);
    }
    .title, .descr {
        padding-inline: 0.5rem;
        font-size: 1.5rem;
        line-height: 2.2rem;
      }
    .title {
        overflow: hidden;
        padding-block-start: 1.5rem;
        padding-block-end: 1rem;
        font-weight: 800;
      }
    .descr {
        padding-block-end: 1rem;
        font-weight: 100;
      }
      .end{
        padding-inline: 0rem;
        font-size: 0rem;
        padding-block-end: 0rem;
        height:30px;
        }
  }

  @media screen and (max-width:1500px){
    .timeline-listcontainer {
        width: 98%;
      }
    .date {
        --dateH: 3rem;
        height: var(--dateH);
        font-size: 1.8rem;
        font-weight: 700;
    }
    /* Date flap */
    .timeline-listitem {
        --inlineP: 1.8rem;
      }
    /* circle */
    .date::after {
        width: 3rem;
        border: 0.5rem solid var(--accent-color);
    }
    .title, .descr {
        padding-inline: 0.5rem;
        font-size: 1.2rem;
        line-height: 1.8rem;
      }
    .title {
        overflow: hidden;
        padding-block-start: 1.5rem;
        padding-block-end: 0.5rem;
        font-weight: 800;
      }
    .descr {
        padding-block-end: 0.5rem;
        font-weight: 100;
      }
    .end{
        padding-inline: 0rem;
        font-size: 0rem;
        padding-block-end: 0rem;
        height:30px;
        }
    .timeline__img img{
        border-radius: 5px;
        padding:10px;
        }
  }

  @media screen and (max-width:1200px){
    .timeline-listcontainer {
        width: 98%;
      }
    .date {
        --dateH: 2.5rem;
        height: var(--dateH);
        font-size: 1.6rem;
        font-weight: 700;
    }
    /* Date flap */
    .timeline-listitem {
        --inlineP: 1.5rem;
      }
    /* circle */
    .date::after {
        width: 2.5rem;
        border: 0.5rem solid var(--accent-color);
    }
    .title, .descr {
        padding-inline: 0.5rem;
        font-size: 1.2rem;
        line-height: 1.8rem;
      }
    .title {
        overflow: hidden;
        padding-block-start: 1rem;
        padding-block-end: 0.5rem;
        font-weight: 800;
      }
    .descr {
        padding-block-end: 0.5rem;
        font-weight: 100;
      }
      .end{
        padding-inline: 0rem;
        font-size: 0rem;
        padding-block-end: 0rem;
        height:20px;
        }
  }

  @media screen and (max-width:1024px){
    .accordion-label {
        padding: 16px;
        height:6rem;
        font-size: 2.4rem;
    }
    .timeline-listcontainer {
        width: 100%;
      }
    .date {
        --dateH: 2rem;
        height: var(--dateH);
        font-size: 1.3rem;
        font-weight: 700;
    }
    /* Date flap */
    .timeline-listitem {
        --inlineP: 1.2rem;
      }
    /* circle */
    .date::after {
        width: 2.2rem;
        border: 0.5rem solid var(--accent-color);
    }
    .title, .descr {
        padding-inline: 0rem;
        font-size: 1rem;
        line-height: 1.4rem;
      }
    .title {
        overflow: hidden;
        padding-block-start: 0.8rem;
        padding-block-end: 0.4rem;
        font-weight: 800;
      }
    .descr {
        padding-block-end: 0.4rem;
        font-weight: 100;
      }
      .end{
        padding-inline: 0rem;
        font-size: 0rem;
        padding-block-end: 0rem;
        height:10px;
        }
  }

  @media screen and (max-width:768px){
    .accordion-label {
        padding: 16px;
        height:5rem;
        font-size: 1.5rem;
    }
    .timeline-listcontainer {
        width: 100%;
      }
    .date {
        --dateH: 2rem;
        height: var(--dateH);
        font-size: 1.2rem;
        font-weight: 700;
    }
    /* Date flap */
    .timeline-listitem {
        --inlineP: 1.2rem;
      }
    /* circle */
    .date::after {
        width: 2.5rem;
        border: 0.45rem solid var(--accent-color);
    }
    .title, .descr {
        padding-inline: 0rem;
        font-size: 0.9rem;
        line-height: 1.3rem;
      }
    .title {
        overflow: hidden;
        padding-block-start: 1rem;
        padding-block-end: 0.3rem;
        font-weight: 800;
      }
    .descr {
        padding-block-end: 0.3rem;
        font-weight: 100;
      }
  }

  @media screen and (max-width:480px){
    .accordion-label {
        padding: 16px;
        height:5rem;
        font-size: 1.2rem;
    }
}