*{
    box-sizing: border-box;
}
.type__MainContainer{
    background-color: #e8e7e5;
    height: 100vh;
    width: 100%;
    display:flex;
    justify-content: center;
    align-items:center;
    min-height: 2500px; 
}

.type__Container{
    background-color: #dcd9d1;
    width:95%;
    height:90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.first_row{
    display:flex;
    height:43%;
    justify-content: space-evenly;
}
.second_row{
    display:flex;
    height:14%;
    width:98%;
    align-self: center;
    border-bottom: 10px solid rgb(155, 155, 155);
    border-top: 10px solid rgb(155, 155, 155);
}
.second_row>h1{
    width:100%;
    display: flex;
    text-align: center;
    align-self: center;
    justify-content: center;
    font-family: Arial, Helvetica, sans-serif;
    color:rgb(0, 0, 0);
    font-size: 3vw;
    font-weight: 900;
    
}
.third_row{
    display:flex;
    height:43%;
    justify-content: space-evenly;
}

.propType{
    max-height: 100%;
    width:30%;
    max-width: 600px;
    display:flex;
    flex-direction: column;
    justify-content: center;
}

.propType__img img{
    object-fit: contain;
    height: 100%;
    width: 100%;
    border-radius: 15px; 
}

.propType__text{
    display:flex;
    min-width: 100%;
    min-height:70px;
    padding:20px 0px;
}

.propType__text h1{
    display:flex;
    justify-content: center;
    width:100%;
    align-items: center;
    text-align:center;
    font-size: 2vw;
    font-weight: 800;
}

@media screen and (max-width:3000px){
    .type__MainContainer{
        height: 100vh;
        min-height: 2000px; 
    }
}

@media screen and (max-width:2560px){
    .type__MainContainer{
        max-height: 1200px;
        min-height: 1100px; 
    }
    .second_row>h1{
        font-size:40px;
        font-weight: 900;
    }
    .propType__text h1{
        font-size: 30px;
        font-weight: 800;
    }
    .propType{
        max-height: 100%;
        width:30%;
        max-width: 300px;
        display:flex;
        flex-direction: column;
        justify-content: center;
    }
}

@media screen and (max-width:1100px){
    .type__MainContainer{
        max-height: 1200px;
        min-height: 1000px; 
    }
    .second_row>h1{
        font-size:30px;
        font-weight: 900;
    }
    .propType__text h1{
        font-size: 22px;
        font-weight: 800;
    }
    .propType__text{
        padding:10px 0px;
        min-height:50px;
    }
    .propType{
        max-height: 100%;
        width:30%;
        max-width: 250px;
        display:flex;
        flex-direction: column;
        justify-content: center;
    }
}

@media screen and (max-width:765px){
    .type__MainContainer{
        max-height: 1200px;
        min-height: 900px; 
    }
    .second_row>h1{
        font-size:25px;
        font-weight: 900;
    }
    .propType__text h1{
        font-size: 20px;
        font-weight: 900;
    }
    .propType__text{
        padding:10px 0px;
        min-height:70px;
    }
    .propType{
        max-height: 100%;
        width:30%;
        max-width: 250px;
        display:flex;
        flex-direction: column;
        justify-content: center;
    }
    .propType__img img{
        border-radius: 10px;   
    }
}

@media screen and (max-width:580px){
    .type__MainContainer{
        max-height: 1200px;
        min-height: 700px; 
    }
    .second_row>h1{
        font-size:22px;
        font-weight: 900;
    }
    .propType__text h1{
        font-size: 20px;
        font-weight: 900;
    }
    .propType__text{
        padding:10px 0px;
        min-height:70px;
    }
    .propType{
        max-height: 100%;
        width:30%;
        max-width: 250px;
        display:flex;
        flex-direction: column;
        justify-content: center;
    }
    .propType__img img{
        border-radius: 5px;   
    }
}

@media screen and (max-width:425px){
    .type__MainContainer{
        max-height: 1200px;
        min-height: 600px; 
    }
    .second_row>h1{
        font-size:20px;
        font-weight: 900;
    }
    .propType__text h1{
        font-size: 20px;
        font-weight: 900;
    }
    .propType__text{
        padding:10px 0px;
        min-height:70px;
    }
    .propType{
        max-height: 100%;
        width:30%;
        max-width: 250px;
        display:flex;
        flex-direction: column;
        justify-content: center;
    }
    .propType__img img{
        border-radius: 5px;   
    }
    .first_row{
        height:40%;
    }
    .third_row{
        height:40%;
    }
    .second_row{
        height:20%
    }
}

@media screen and (max-width:370px){
    .type__MainContainer{
        max-height: none;
        min-height: none; 
        height:1800px;
    }
    .type__Container{
        width:95%;
        height:98%;
    }
    .second_row>h1{
        font-size:20px;
        font-weight: 900;
    }
    .propType__text h1{
        font-size: 18px;
        font-weight: 900;
    }
    .propType__text{
        padding:10px 0px;
        min-height:30px;
        order:1;
    }
    .propType{
        max-height: 100%;
        width:75vw;
        max-width: 250px;
        display:flex;
        flex-direction: column;
        justify-content: center;
        margin-top:8px;
    }
    .propType__img img{
        border-radius: 5px;   
    }
    .first_row{
        height:54%;
        order:2;
        flex-direction: column;
        align-items: center;
        justify-content: bottom;
    }
    .third_row{
        height:36%;
        order:3;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        
    }
    .second_row{
        height:10%;
        order:1;
    }
}

@media screen and (max-width:300px){
    .type__MainContainer{
        max-height: none;
        min-height: none; 
        height:1600px;
    }
}

@media screen and (max-width:260px){
    .type__MainContainer{
        max-height: none;
        min-height: none; 
        height:1400px;
    }
}

@media screen and (max-width:220px){
    .type__MainContainer{
        max-height: none;
        min-height: none; 
        height:1300px;
    }
    .second_row>h1{
        font-size:16px;
        font-weight: 900;
    }
}

@media screen and (max-width:200px){
    .type__MainContainer{
        max-height: none;
        min-height: none; 
        height:1200px;
    }
}